<template>
	<!-- 이력서 등록요청 팝업 -->
	<div class="notice_popup">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="tit">이력서 등록요청하기</div>
		<div class="member"><span class="name">{{data.mberNm}} ({{data.gender | gender}} / {{data.birthYear | birthYear}}) 님에게</span><br/>이력서 등록을 요청하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="$emit('close', true)">예</div>
		</div>
	</div>
</template>
<script>
export default {
	props: { param:Object },
	data() {
		return {
			data: {},
		};
	},
	mounted(){
		this.$.httpPost('/api/mem/prf/getTecSummary', {tecMberSeq : this.param.tecMberSeq})
			.then(res => {
				this.data = res.data;
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
	},
};
</script>
